
.proxy-container {
  padding-top: 200px;
  background-color: #f4f4f4;
  border-radius: 15px;

  img {
    border-radius: 15px;
  }

  .bg-changer {
    background-color: rgb(0, 0, 0) !important;
    border-radius: 15px;
  }

  .text-changer {
    color: white !important;
    font-size: 2rem;
    font-weight: 600;
  }

  .proxy-content {
    margin-bottom: 150px !important;
    .left-section {
      .mocks {
        display: flex !important;
        flex-wrap: wrap !important;
        justify-content: center;

        img {
          font-size: 1.4rem;
          font-weight: 600;
          width: 200px;
          height: 200px;
        }
        grid-gap: 15px !important;
      }
    }
  }
}


// Variables
$primary-color: #e3495d;
$secondary-color: #e3495d;
$text-color: #1f2937;
$spacing-base: 2rem;
$border-radius: 0.5rem;

// Mixins
@mixin flex($direction: row, $justify: flex-start, $align: flex-start) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

@mixin section-spacing {
  margin-bottom: $spacing-base * 2;
  padding: $spacing-base;
}

.proxy-content {
  @include flex(row, space-between, flex-start);
  max-width: 1200px;
  margin: 0 auto;
  padding: $spacing-base;
  gap: $spacing-base * 2;


  .left-section {
    flex: 1;

    h1 {
      font-size: 2.5rem;
      font-weight: 700;
      color: $text-color;
      margin-bottom: $spacing-base;

      strong {
        background: linear-gradient(135deg, $primary-color, $secondary-color);
        -webkit-background-clip: text;
        color: transparent;
      }
    }

    .proxy-product {
      @include section-spacing;
      background: rgba(255, 255, 255, 0.05);
      border-radius: $border-radius;

      h2 {
        font-size: 1.8rem;
        font-weight: 600;
        margin-bottom: $spacing-base;
        color: $text-color;
      }

      p {
        font-size: 1.1rem;
        line-height: 1.7;
        color: rgba($text-color, 0.9);
      }

      .project {
        margin-top: $spacing-base;
      }
    }

    .proxy-pain-point {
      @include section-spacing;
      background: rgba($primary-color, 0.05);
      border-radius: $border-radius;
      border-left: 4px solid $primary-color;
      display: flex;
      flex-direction: column;
      grid-gap: 20px;

      img {
        font-size: 1.4rem;
        font-weight: 600;
        margin-bottom: $spacing-base * 0.5;
        color: $primary-color;
        width: 200px;
        height: 200px;
      }

      p {
        font-size: 1.1rem;
        line-height: 1.6;
      }
    }
  }

  .right-section {
    flex: 1;

    .goal-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .right-user-section {
      @include section-spacing;
      background: rgba(255, 255, 255, 0.08);
      border-radius: $border-radius;

      h2 {
        font-size: 1.6rem;
        font-weight: 600;
        margin-bottom: $spacing-base;
        color: $text-color;

        &:not(:first-child) {
          margin-top: $spacing-base;
        }
      }

      p {
        font-size: 1.1rem;
        line-height: 1.7;
        color: rgba($text-color, 0.9);
      }
    }
  }
}

@media (max-width: 1024px) {
  .proxy-content {
    flex-direction: column;
    padding: $spacing-base;

    .left-section,
    .right-section {
      width: 100%;
    }
  }
}

@media (max-width: 640px) {
  .proxy-content {
    padding: $spacing-base * 0.5;

    .left-section {
      h1 {
        font-size: 2rem;
      }

      .proxy-product,
      .proxy-pain-point {
        padding: $spacing-base * 0.75;
      }
    }
  }
}