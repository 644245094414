/* Home.css */

.Case-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 200px 0px;
  width: 100%;
  grid-gap:15px;

  .logo {
    width: 100%;
    height: 100%;
  }

  .card  {
    flex: 1 1 calc(50% - 20px); // Two boxes per row, accounting for the gap
    max-width: calc(50% - 20px);
    min-width: 300px; // Minimum width for responsiveness
    height: 100%;
    background-color: #f4f4f4;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;

w    .img-container {
      width: 100%;
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      
      img {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }

}


.home-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 100px 20px !important;
}

.hero-section {
  background-image: linear-gradient(to bottom, #333, #555);
  background-size: 100% 300px;
  background-position: 0% 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.hero-section h1 {
  font-size: 48px;
  margin-bottom: 10px;
}

.features-section {
  margin-top: 50px;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.feature-card {
  background-color: #f7f7f7;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.feature-card i {
  font-size: 24px;
  margin-bottom: 10px;
}

.testimonials-section {
  margin-top: 50px;
}

.testimonial-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}

.testimonial-card {
  background-color: #f7f7f7;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.testimonial-card p {
  font-size: 18px;
  margin-bottom: 10px;
}

.footer-section {
  margin-top: 50px;
  text-align: left;
}